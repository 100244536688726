import React from "react"
import { graphql, PageProps } from "gatsby"

import { Heading } from "@/components/heading"
import { Layout } from "@/components/layout"
import { Form } from "@/components/form"
import { ArticleCategories } from "@/components/articleCategories"
import { ArticleListHeading } from "@/components/articleListHeading"
import { ArticleList } from "@/components/articleList"

import styles from "./index.module.scss"

const BlogListPage: React.VFC<PageProps<GatsbyTypes.BlogPageQuery>> = ({
  data,
}) => {
  const blogNode = data.allMicrocmsBlog.nodes
  const categoryNode = data.allMicrocmsCategories.nodes

  return (
    <Layout>
      <div className={styles.blogWrapper}>
        <div className={styles.blog}>
          <Heading
            level="h1"
            size="Large"
            title={`ECの担当者様に役立つ資料を更新。`}
            subTitle="お役たち資料"
          />
          <div className={styles.spacer} aria-hidden="true"></div>
          <ArticleCategories categories={categoryNode} path="blog" />
          <ArticleListHeading title="お役たち資料一覧" />
          <ArticleList articles={blogNode} path="blog" />
        </div>
      </div>
      <Form />
    </Layout>
  )
}

export const query = graphql`
  query BlogPage {
    allMicrocmsBlog(sort: { fields: date, order: DESC }) {
      nodes {
        blogId
        img {
          url
          height
          width
        }
        date(formatString: "YYYY/MM/DD")
        title
      }
    }
    allMicrocmsCategories {
      nodes {
        categoriesId
        name
        pathName
      }
    }
  }
`

export default BlogListPage

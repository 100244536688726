import React from "react"

import styles from "./index.module.scss"
import { useLocation } from "@reach/router"
import { ArticleCategory } from "./articleCategory"

type CategoryItem = {
  readonly categoriesId?: string
  readonly name?: string
  readonly pathName?: string
}

type Props = {
  categories: readonly CategoryItem[]
  readonly path: string
}

export const ArticleCategories: React.VFC<Props> = ({ categories, path }) => {
  const param = useLocation()
  const pattern = /[^/]+/g
  const uri = param.pathname.match(pattern)
  return (
    <ul className={styles.articleCategories}>
      {categories.map(({ categoriesId, name, pathName }) => (
        <ArticleCategory
          key={categoriesId}
          name={name}
          pathName={pathName}
          path={path}
          isCurrent={uri && uri[1] === pathName ? true : false}
        />
      ))}
    </ul>
  )
}

import React from "react"
import { Link } from "gatsby"

import styles from "./index.module.scss"

type Props = {
  name?: string
  pathName?: string
  path: string
  isCurrent: boolean
}

export const ArticleCategory: React.VFC<Props> = ({
  name,
  pathName,
  path,
  isCurrent,
}) => {
  return (
    <li
      className={`${styles.articleCategory} ${
        isCurrent ? styles.currentCategory : ""
      }`}
    >
      <Link
        className={styles.articleCategoryLink}
        to={`/${path}/${pathName}`}
      >
        {name}
      </Link>
    </li>
  )
}

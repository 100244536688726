import React from "react"
import styles from './index.module.scss'

type Props = {
  title?: string
}

export const ArticleListHeading:React.VFC<Props> = ({title}) => {
  return (
    <h2 className={styles.articleListHeading}>
      {title}
    </h2>
  )
}

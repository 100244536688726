import React from "react"
import { Link } from "gatsby"

import styles from "./index.module.scss"

type Img = {
  url?: string
  width?: number
  height?: number
}

type Article = {
  readonly blogId?: string
  readonly newsId?: string
  readonly title?: string
  readonly date?: string
  readonly img?: Img
}

type Props = {
  readonly articles: readonly Article[]
  readonly path: string
}

export const ArticleList: React.VFC<Props> = ({ articles, path }) => {
  return (
    <ul className={styles.articleList}>
      {articles &&
        articles.map(item => {
          const id = item.blogId ?? item.newsId
          return (
            <li key={id} className={styles.articleItem}>
              <Link to={`/${path}/${id}`} className={styles.articleItemLink} />
              <div className={styles.articleItemImageWrapper}>
                <img
                  src={item.img?.url}
                  alt=""
                  width={item.img?.width}
                  height={item.img?.height}
                />
              </div>
              <h3 className={styles.articleItemHeading}>{item.title}</h3>
              <p className={styles.articleItemDate}>{item.date}</p>
            </li>
          )
        })}
    </ul>
  )
}
